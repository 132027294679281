<template>
  <section>
    <div class="mt-3 md:mt-0">
      <t-dropdown>
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            class="focus:outline-none"
            aria-label="Org User Profile Status Dropdown"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <dropdown-trigger-oto class="flex items-center justify-between">
              <div class="flex flex-col items-baseline justify-start pl-5">
                <span class="text-xs font-medium text-oBlack">
                  {{ statusTextLabel }}
                </span>
                <span
                  class="text-xs font-semibold text-14px"
                  :class="getStatusTextClass"
                  >{{ getStatusTextCurrent }}</span
                >
              </div>
              <svg
                class="w-6 h-6 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </dropdown-trigger-oto>
          </button>
        </template>
        <template v-slot:default="{ hide }">
          <div
            @click="hide()"
            class="py-1 mt-px mr-5 bg-white rounded-md shadow-md"
            style="right: 100px"
          >
            <t-dropdown-item
              v-if="statusFirstEnabled"
              @click="handleFirstAction()"
            >
              {{ statusTextFirst }}
            </t-dropdown-item>

            <t-dropdown-item @click="handleActivation()">
              {{ statusTextActivate }}
            </t-dropdown-item>

            <t-dropdown-item @click="handleDeactivation()">
              {{ statusTextDeactivate }}
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>
  </section>
</template>

<script>
// import xMan from "@/utils/xMan";

import TDropdownItem from '@/components/dropdown/TDropdownItem'
import DropdownTriggerOto from '@/components/dropdown/DropdownTriggerOto'

export default {
  props: {
    method: { reqired: false },
    endpoint: { reqired: false },

    // key-val
    statusCurrent: { reqired: false },

    statusKeyActivate: { reqired: false, default: 'status' },
    statusValueActivate: { reqired: false, default: 'A' },

    statusKeyDeactivate: { reqired: false, default: 'status' },
    statusValueDeactivate: { reqired: false, default: 'D' },

    // Texts
    statusTextLabel: { reqired: false, default: 'Status' },

    statusTextActivate: { reqired: false, type: String, default: 'Activate' },
    statusTextOnActivate: { reqired: false, default: 'Active' },

    statusTextDeactivate: { reqired: false, default: 'Deactivate' },
    statusTextOnDeactivate: { reqired: false, default: 'Inactive' },

    // Extra (first->first dropdown menu position)
    statusFirstEnabled: { reqired: false, type: Boolean, default: false },
    statusKeyFirst: { reqired: false, default: 'status' },
    statusValueFirst: { reqired: false, default: 'H' },
    statusTextFirst: { reqired: false, default: 'Hold' },
    statusTextOnFirst: { reqired: false, default: 'On Hold' },
    statusTextOnFirstClass: { reqired: false, default: 'text-oOrange' },
  },
  components: { TDropdownItem, DropdownTriggerOto },
  data() {
    return {
      isLoading: false,
      profile_status: this.statusCurrent,
    }
  },
  // mounted() {
  //   console.log("mou", this.profile_status);
  // },
  computed: {
    getStatusTextCurrent() {
      if (this.profile_status === this.statusValueFirst) {
        return this.statusTextOnFirst
      }
      if (this.profile_status === this.statusValueActivate) {
        return this.statusTextOnActivate
      }
      if (this.profile_status === this.statusValueDeactivate) {
        return this.statusTextOnDeactivate
      }
      // defaults to inactive
      return this.statusTextOnDeactivate
    },
    getStatusTextClass() {
      if (this.profile_status === this.statusValueFirst) {
        return this.statusTextOnFirstClass
      }
      if (this.profile_status === this.statusValueActivate) {
        return 'text-oGreen'
      }
      if (this.profile_status === this.statusValueDeactivate) {
        return 'text-oRed'
      }
      // defaults to inactive
      return 'text-oRed'
    },
  },
  watch: {
    statusCurrent: function() {
      this.profile_status = this.statusCurrent
      console.log('dptS-wat-prs', this.profile_status)
    },
  },
  methods: {
    handleFirstAction() {
      this.UpdateStatus(this.statusKeyFirst, this.statusValueFirst)
      this.$emit('first')
    },
    handleActivation() {
      this.UpdateStatus(this.statusKeyActivate, this.statusValueActivate)
      this.$emit('activate')
    },
    handleDeactivation() {
      this.UpdateStatus(this.statusKeyDeactivate, this.statusValueDeactivate)
      this.$emit('deactivate')
    },
    async UpdateStatus(statusKey, statusVal) {
      this.isLoading = true
      var formData = new FormData()
      formData.append(statusKey, statusVal)

      // console.log("resqd", data);
      console.log('resqd', formData)
      await this.$http({
        method: this.method,
        url: this.endpoint,
        data: formData,
      })
        .then((res) => {
          this.profile_status = statusVal
          console.log('pro-st=', statusKey)
          console.log('pro-stCurr=', this.profile_status)
          console.log('pro-st', res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Status has updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('pro-st-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Failed to update status',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>

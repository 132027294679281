var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"mt-3 md:mt-0"},[_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"focus:outline-none",attrs:{"aria-label":"Org User Profile Status Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('dropdown-trigger-oto',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex flex-col items-baseline justify-start pl-5"},[_c('span',{staticClass:"text-xs font-medium text-oBlack"},[_vm._v(" "+_vm._s(_vm.statusTextLabel)+" ")]),_c('span',{staticClass:"text-xs font-semibold text-14px",class:_vm.getStatusTextClass},[_vm._v(_vm._s(_vm.getStatusTextCurrent))])]),_c('svg',{staticClass:"w-6 h-6 mr-2",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])],1)]}},{key:"default",fn:function(ref){
        var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px mr-5 bg-white rounded-md shadow-md",staticStyle:{"right":"100px"},on:{"click":function($event){return hide()}}},[(_vm.statusFirstEnabled)?_c('t-dropdown-item',{on:{"click":function($event){return _vm.handleFirstAction()}}},[_vm._v(" "+_vm._s(_vm.statusTextFirst)+" ")]):_vm._e(),_c('t-dropdown-item',{on:{"click":function($event){return _vm.handleActivation()}}},[_vm._v(" "+_vm._s(_vm.statusTextActivate)+" ")]),_c('t-dropdown-item',{on:{"click":function($event){return _vm.handleDeactivation()}}},[_vm._v(" "+_vm._s(_vm.statusTextDeactivate)+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }